import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import CountryService from '@/services/country.service'
import { CsvFileConfig } from '@/models/CsvFileConfig'
import GroupService from '@/services/group.service'
import { Import } from '@/classes/Import.js'
import vMultiselectListbox from 'vue-multiselect-listbox'
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css'
import UserService from '@/services/user.service'
import EventBus from '@/util/EventBus'
import MultiSelect from '@/components/MultiSelect/MultiSelect.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import ContactsTable from './ContactsTable/ContactsTable.vue'
import Preview from '../ImportContacts/Preview/Preview.vue'
import { KlaviyoObjectType } from '@/classes/integrations/klaviyo/KlaviyoObjectType'
import KlaviyoService from '@/services/external/klaviyo.service'
import { ImportTypes } from '../ImportContacts/enums/ImportType'

export default {
  name: 'ImportKlaviyo',
  components: {
    HeaderTopDashboard,
    ButtonMultimedia,
    ContactsTable,
    vMultiselectListbox,
    Preview,
    MultiSelect,
  },
  data () {
    return {
      importObject: new Import(),
      contactsFileUploadResponse: null,
      preview: [],
      skipHeader: null,
      delimiter: null,
      stepperStep: 1,
      dataCountry: [],
      dataCountryKeys: [],
      csvFileConfig: new CsvFileConfig(),
      confirmContactConsent: false,
      userGroups: [],
      collisionActionSelected: null,
      importGroups: [],
      newGroups: [],
      newGroupName: '',
      loadingFirstStepAction: false,
      customFields: [],
      selectedField: null,
      selectedType: '',
      availableFieldsAdvanced: [],
      typeInfoItems: [],
      selectedColumn: null,
      newFieldType: 'string',
      newFieldName: '',
      newFieldColumn: null,
      countries: [],
      KlaviyoObjectType,
      klaviyoSelectedType: null,
      klaviyoSelectedList: null,
      klaviyoSelectedSegment: null,
      klaviyoLists: [],
      klaviyoSegments: [],
      klaviyoContacts: [],
      klaviyoColumnHeaders: [],
      isSending: false,
      maxSyncs: 0,
      syncData: null,
    }
  },
  watch: {
  },
  computed: {
    frequencies: function () {
      return [
        // { id: 30, name: this.$t('30 minutos') },
        { id: 60, name: this.$t('60 minutos') },
        { id: 90, name: this.$t('90 minutos') },
        { id: 120, name: this.$t('120 minutos') },
        { id: 180, name: this.$t('3 horas') },
        { id: 360, name: this.$t('6 horas') },
        { id: 720, name: this.$t('12 horas') },
        { id: 1440, name: this.$t('24 horas') },
        { id: 2880, name: this.$t('2 días') },
        { id: 4320, name: this.$t('3 días') },
        { id: 10080, name: this.$t('7 días') },
        { id: 21600, name: this.$t('15 días') },
        { id: 43200, name: this.$t('30 días') },
      ]
    },
    klaviyoColumnHeadersItems: function () {
      return this.klaviyoColumnHeaders.map((header, idx) => ({ value: idx + 1, text: header }))
    },
    requiresMobilePhoneCountry () {
      return !this.importObject.columnsMap?.email && !this.importObject.columnsMap?.landline
    },
    requiresLandlineCountry () {
      return !this.importObject.columnsMap?.email && !this.importObject.columnsMap?.phone
    },
    requiresEmail () {
      return !this.importObject.columnsMap?.phone && !this.importObject.columnsMap?.landline
    },
    requiresCountry () {
      return this.importObject.columnsMap?.phone || this.importObject.columnsMap?.landline
    },
    canAddNewSync () {
      this.maxSyncs = this.$store.getters['auth/getUser'].maxSyncs ?? this.syncData?.maxTotalSyncs ?? 0
      return this.syncData?.userSyncs < this.maxSyncs
    },
  },
  methods: {
    setColumnName (column) {
      this.importObject.columnsMap[column + 'ColumnName'] = this.klaviyoColumnHeadersItems[this.importObject.columnsMap[column] - 1].text
    },
    changeFrequency () {
      if (this.importObject.frequency !== '') {
        this.importObject.type = ImportTypes.KLAVIYO_SYNCHRONIZED
      } else {
        this.importObject.type = ImportTypes.MANUAL
      }
    },
    save () {
      this.isSending = true
      this.importObject.jsonData = this.klaviyoContacts
      this.importObject.jsonHeaders = this.klaviyoColumnHeaders
      this.importObject.columnsMap.jsonHeaders = this.klaviyoColumnHeaders
      this.importObject.isListOrSegment = this.klaviyoSelectedType
      this.importObject.idListOrSegment = this.klaviyoSelectedList ?? this.klaviyoSelectedSegment
      this.importObject.name = this.klaviyoSelectedType === KlaviyoObjectType.LISTS
        ? this.$t('Lista') + ': ' + this.klaviyoLists.find(l => l.value === this.klaviyoSelectedList).text
        : this.$t('Segmento') + ': ' + this.klaviyoSegments.find(s => s.value === this.klaviyoSelectedSegment).text

      KlaviyoService.importContacts(this.importObject)
      .catch((error) => {
        console.log('errorp', error)
        console.log('errorp.response', error.response)
      })
      // .catch(() => EventBus.$emit('showAlert', 'danger', this.$t('Hubo un error al guardar la imporación de contactos').toString()))

      EventBus.$emit('showAlert', 'success', this.$t('El proceso puede tardar dependiendo de la cantidad de contactos importados.<br>Te enviaremos un correo cuando la importación acabe.'))
      if (this.importObject.isManual()) {
        setTimeout(() => this.$router.push('/tools/myimports'), 2000)
      } else {
        setTimeout(() => this.$router.push('/tools/mysync'), 2000)
      }
    },
    firstStepAction () {
      this.stepperStep = 2
    },
    selectedGroups (newGroups) {
      this.importObject.groupsIds = newGroups.map(g => g.id)
    },
    removeGroup (groupName) {
      const index = this.importObject.newGroups.indexOf(groupName)
      if (index > -1) {
        this.importObject.newGroups.splice(index, 1)
      }
    },
    checkAndAddGroup () {
      GroupService.exists({ name: this.newGroupName }).then(() => {
        this.importObject.newGroups.push(this.newGroupName)
        this.newGroupName = ''
      }, () => {
       EventBus.$emit('showAlert', 'danger', this.$t('Ya existe un grupo con el nombre').toString() + ': ' + this.newGroupName)
      })
    },
    getGroups () {
      GroupService.getUsers()
        .then(
          (response) => {
            this.userGroups = response
          },
          () => { },
        )
        .finally(() => {
        })
    },
    getCoutries () {
      CountryService.getCountries()
        .then(
          (countries) => {
            for (const key in countries) {
              const element = countries[key]
              this.countries.push({ value: key, text: element })
            }
          },
          () => { },
        )
        .finally(() => {
        })
    },
    setAvailableFields () {
      UserService.getAvailableFieldsByType()
      .then(
        (response) => {
          const availableFields = response.availableFields
          for (const fieldsGroupKey in availableFields) {
            const fieldsGroup = availableFields[fieldsGroupKey]
            if (fieldsGroupKey !== 'default' && Object.keys(fieldsGroup.items).length > 0) {
              this.customFields.push({ header: fieldsGroup.name })
              this.customFields.push({ divider: true })
              // eslint-disable-next-line max-depth
              for (const fieldKey in fieldsGroup.items) {
                const fieldValue = fieldsGroup.items[fieldKey]
                this.customFields.push({ value: fieldKey, text: fieldValue })
              }
            }
          }
        })


        UserService.getAvailableFieldsAdvancedByType()
        .then(
          (response) => {
            this.availableFieldsAdvanced = response.availableFieldsAdvancedByType
          })
    },
    // eslint-disable-next-line complexity
    existingColumnChange () {
      var type = 'string'
      if (typeof this.availableFieldsAdvanced.optional.items[this.selectedField] !== 'undefined' &&
        typeof this.availableFieldsAdvanced.optional.items[this.selectedField].type !== 'undefined') {
        type = this.availableFieldsAdvanced.optional.items[this.selectedField].type
      } else if (typeof this.availableFieldsAdvanced.custom.items[this.selectedField] !== 'undefined' &&
        typeof this.availableFieldsAdvanced.custom.items[this.selectedField].type !== 'undefined') {
        type = this.availableFieldsAdvanced.custom.items[this.selectedField].type
      }

      var format = ''
      if (typeof this.availableFieldsAdvanced.optional.items[this.selectedField] !== 'undefined' &&
        typeof this.availableFieldsAdvanced.optional.items[this.selectedField].format !== 'undefined') {
        format = this.availableFieldsAdvanced.optional.items[this.selectedField].format
      } else if (typeof this.availableFieldsAdvanced.custom.items[this.selectedField] !== 'undefined' &&
        typeof this.availableFieldsAdvanced.custom.items[this.selectedField].format !== 'undefined') {
        format = this.availableFieldsAdvanced.custom.items[this.selectedField].format
      }

      var field = type
      if (format) {
        field += '_' + format
      }
      this.selectedType = field
    },
    addExistingField () {
      var split = this.selectedType.split('_')
      var type = split[0]
      var format = ''

      if (split.length > 1) {
        format = split[1]
      }

      this.importObject.fieldsMap[this.selectedField] = { col: this.selectedColumn, type: type, format: format }
      this.selectedField = ''
      this.selectedType = ''
      this.selectedColumn = ''
    },
    removeCustomField (key) {
      if (this.importObject.fieldsMap[key]) {
        delete this.importObject.fieldsMap[key]
        this.$forceUpdate()
      }
    },
    trim (str) {
      return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')
    },
    normalizeFieldName (name) {
      if (!name) {
        return name
      }
      return this.trim(name.replace(/\s/g, '_').toLowerCase())
    },
    // eslint-disable-next-line complexity
    addNewField () {
      var myRegxp = /^[.a-zA-Z0-9 _-]+$/
      if (myRegxp.test(this.newFieldName) === false) {
        EventBus.$emit('showAlert', 'warning', this.$t('El nombre del nuevo campo personalizado no puede contener caracteres especiales'))
        return
      }

      var name = this.normalizeFieldName(this.newFieldName)

      if (this.availableFieldsAdvanced) {
        for (var i in this.availableFieldsAdvanced) {
          // eslint-disable-next-line max-depth
          if (this.availableFieldsAdvanced[i]) {
            // eslint-disable-next-line max-depth
            for (var j in this.availableFieldsAdvanced[i].items) {
              // eslint-disable-next-line max-depth
              if (this.availableFieldsAdvanced[i].items[j].name) {
                // eslint-disable-next-line max-depth
                if (name.toLowerCase() === this.availableFieldsAdvanced[i].items[j].name) {
                  EventBus.$emit('showAlert', 'warning', this.$t('El nombre del nuevo campo personalizado ya existe'))
                  return
                }
              }
            }
          }
        }
      }

      var split = this.newFieldType.split('_')
      var type = split[0]
      var format = ''
      if (split.length > 1) {
        format = split[1]
      }

      this.importObject.fieldsMap[name] = { col: this.newFieldColumn, type: type, format: format }

      this.newFieldName = ''
      this.newFieldType = 'string'
      this.newFieldColumn = null
    },
    setTypeInfoItems () {
      this.typeInfoItems = [
        { value: 'date_d-m-Y', text: this.$t('Fecha').toString() + ' (' + this.$t('día') + ' ' + this.$t('mes') + ' ' + this.$t('año') + '), ' + this.$t('ej').toString() + ': 23-11-2017, ' + this.$t('ej').toString() + ': 23/11/2017' },
        { value: 'date_m-d-Y', text: this.$t('Fecha').toString() + ' (' + this.$t('mes') + ' ' + this.$t('día') + ' ' + this.$t('año') + '), ' + this.$t('ej').toString() + ': 11-23-2017, ' + this.$t('ej').toString() + ': 11/23/2017' },
        { value: 'date_Y-m-d', text: this.$t('Fecha').toString() + ' (' + this.$t('año') + ' ' + this.$t('mes') + ' ' + this.$t('día') + '), ' + this.$t('ej').toString() + ': 2017-11-23, ' + this.$t('ej').toString() + ': 2017/11/23' },
        { value: 'string', text: this.$t('Texto').toString() + ', ' + this.$t('ej').toString() + ': ' + this.$t('Esto es un texto').toString() },
        { value: 'decimal', text: this.$t('Entero').toString() + ', ' + this.$t('ej').toString() + ': 10' },
        { value: 'decimal_comma', text: this.$t('Decimal separado por coma').toString() + ', ' + this.$t('ej').toString() + ': 100,00' },
        { value: 'decimal_point', text: this.$t('Decimal separado por punto').toString() + ', ' + this.$t('ej').toString() + ': 10.00' },
      ]
    },
    getRowFieldName: function (key) {
      return this.customFields.find(field => field.value === key)?.text || key
    },
    getRowTypeName: function (row) {
      const typeFormat = row.type + (row.format ? `_${ row.format }` : '')
      return this.typeInfoItems.find(typeInfo => typeInfo.value === typeFormat)?.text || typeFormat
    },
    getKlaviyoData () {
      this.klaviyoContacts = []
      if (this.klaviyoSelectedType === KlaviyoObjectType.LISTS) {
        this.klaviyoLists = []
        this.klaviyoSelectedList = null
        this.getKlaviyoLists()
      } else {
        this.klaviyoSegments = []
        this.klaviyoSelectedSegment = null
        this.getKlaviyoSegments()
      }
    },
    getKlaviyoLists () {
      EventBus.$emit('showLoading', true, this.$t('Cargando listas...'))
      KlaviyoService.getLists()
      .then(
        (lists) => {
          if (lists.success) {
            this.klaviyoLists = lists.data.map(list => ({ value: list.id, text: list.attributes.name }))
          } else {
            EventBus.$emit('showAlert', 'warning', lists.message)
          }
        },
        (error) => {
          console.log(error)
          EventBus.$emit('showAlert', 'warning', error.response.data.message)
        },
      )
      .finally(() => {
        EventBus.$emit('showLoading', false)
      })
    },
    getKlaviyoSegments () {
      EventBus.$emit('showLoading', true, this.$t('Cargando segmentos...'))
      KlaviyoService.getSegments()
      .then(
        (segments) => {
          if (segments.success) {
            this.klaviyoSegments = segments.data.map(list => ({ value: list.id, text: list.attributes.name }))
          } else {
            EventBus.$emit('showAlert', 'warning', segments.message)
          }
        },
        (error) => {
          console.log(error)
          EventBus.$emit('showAlert', 'warning', error.response.data.message)
        },
      )
      .finally(() => {
        EventBus.$emit('showLoading', false)
      })
    },
    getKlaviyoListProfiles () {
      EventBus.$emit('showLoading', true)
      KlaviyoService.getListProfiles(this.klaviyoSelectedList)
      .then(
        (profiles) => {
          if (profiles.success) {
            this.klaviyoContacts = profiles.contacts
            this.klaviyoColumnHeaders = profiles.headers
          } else {
            EventBus.$emit('showAlert', 'warning', profiles.message)
          }
        },
        (error) => {
          console.log(error)
          EventBus.$emit('showAlert', 'warning', error.response.data.message)
        },
      )
      .finally(() => {
        EventBus.$emit('showLoading', false)
      })
    },
    getKlaviyoSegmentProfiles () {
      EventBus.$emit('showLoading', true)
      KlaviyoService.getSegmentProfiles(this.klaviyoSelectedSegment)
      .then(
        (profiles) => {
          if (profiles.success) {
            this.klaviyoContacts = profiles.contacts
            this.klaviyoColumnHeaders = profiles.headers
          } else {
            EventBus.$emit('showAlert', 'warning', profiles.message)
          }
        },
        (error) => {
          console.log(error)
          EventBus.$emit('showAlert', 'warning', error.response.data.message)
        },
      )
      .finally(() => {
        EventBus.$emit('showLoading', false)
      })
    },
    getSyncData () {
      KlaviyoService.getSyncData()
      .then(syncData => {
        this.syncData = syncData
      })
    },
  },
  created () {
  },
  mounted () {
    this.importObject.id = this.$route.params.id
    this.importObject.type = this.$route.params.type !== ImportTypes.MANUAL ? ImportTypes.KLAVIYO_SYNCHRONIZED : ImportTypes.MANUAL
    if (this.importObject.isSynchronized()) {
      this.getSyncData()
    }
    this.setTypeInfoItems()
    this.getCoutries()
    this.getGroups()
    this.setAvailableFields()
  },
}
