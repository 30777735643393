export default {
  props: {
    contacts: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  name: 'ContactsTable',
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  watch: {
  },
  created () {
  },
  mounted () {
  },
}
