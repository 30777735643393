import { Service } from '../service'

const PATH = 'klaviyo'

class KlaviyoService extends Service {
  getLists (params = {}) {
    return this.get(PATH + '/get-lists', params)
  }

  getListProfiles (listId, params = {}) {
    return this.get(PATH + '/get-list-profiles/' + listId, params)
  }

  getSegments (params = {}) {
    return this.get(PATH + '/get-segments', params)
  }

  getSegmentProfiles (segmentId, params = {}) {
    return this.get(PATH + '/get-segment-profiles/' + segmentId, params)
  }

  importContacts (params = {}, config = {}) {
    return this.post(PATH + '/import-contacts', params, config)
  }

  getSyncData () {
    return this.get(PATH + '/get-sync-data')
  }
}

export default new KlaviyoService()
