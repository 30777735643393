export default {
  props: ['preview'],
  name: 'Preview',
  components: { },
  data () {
    return {
    }
  },
  methods: {
  },
  created () {
  },
  mounted () {
  },
}
